@use 'sass:color';
@use 'color-vars';

$btn-transition-time: 150ms;
$btn-ring-width: var(--focus-ring-thickness);

// Only things that change with `$color` go in these mixins.
@mixin btn-color($color) {
  color: var(--white);
  background-color: $color;

  &:active,
  &:focus,
  &:hover {
    background-color: color.scale($color, $lightness: 20%);
    box-shadow: 0 0 0 $btn-ring-width $color;
  }

  &:disabled {
    background-color: color.scale($color, $saturation: -30%);
  }
}

@mixin btn-color-outline($color) {
  color: var(--white);
  // IDEA: Try making the background color into a frosted glass material.
  background-color: transparent;
  box-shadow: inset 0 0 0 $btn-ring-width $color;

  &:active,
  &:focus,
  &:hover {
    background-color: color.scale($color, $lightness: 20%);
    box-shadow: 0 0 0 $btn-ring-width $color;
  }

  &:disabled {
    background-color: color.scale($color, $saturation: -30%);
  }
}

.btn {
  display: inline-block;
  margin-top: 0.125rem;
  padding: 0.75rem 1.125rem;
  border-radius: 0.5rem;
  font-weight: 700;
  box-shadow: 0 0 5px #00000044;
  cursor: pointer;
  transition: all $btn-transition-time;
  border: none;

  // Remove the underline from links styled as buttons.
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    // Remove the default `focus` outline.
    // This gets replaced with a `box-shadow` when the button is hovered.
    outline: 0;
  }

  &:disabled {
    box-shadow: none !important;
    cursor: default;
  }
}

.btn-mango {
  @include btn-color(color-vars.$mango);
}

.btn-outline-mango {
  @include btn-color-outline(color-vars.$mango);
}

.btn-gray {
  @include btn-color(color-vars.$slate-600);
}

.btn-success {
  @include btn-color(color-vars.$success-green);
}
