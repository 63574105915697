@use 'color-vars';

:root {
  --gutter-size: 1.2rem;
  --color-dark: #{color-vars.$color-dark};
  --focus-ring-thickness: 4px;
}

html {
  scroll-behavior: smooth;

  // Some of the animated elements have a starting position that
  // puts them outside of the normal page width.
  // Prevent these elements from making the page wider than the screen size.
  overflow-x: hidden;
}

body {
  font-family:
    Inter,
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    sans-serif;
  color: var(--color-dark);
  line-height: 1.5;
}

.contain,
.contain-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: min(var(--screen-lg), calc(100vw - var(--gutter-size) * 2));
}

.contain-sm {
  margin-left: auto;
  margin-right: auto;
  max-width: min(var(--screen-sm), calc(100vw - var(--gutter-size) * 2));
}

.contain-gutter {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(100vw - var(--gutter-size));
}

// Glassmorphism related properties
.glass {
  backdrop-filter: blur(10px);
  background-clip: padding-box;
  background-color: rgba(30, 30, 30, 0.7);
  padding: 2rem;
  border-radius: 2rem;
}

// Hide the Google ReCAPTCHA badge
// https: //www.npmjs.com/package/react-google-recaptcha#hiding-the-recaptcha
.grecaptcha-badge {
  visibility: hidden;
}
